@keyframes fillAction {
    0% {
        -webkit-transform: translate(0, 150px);
    }

    100% {
        -webkit-transform: translate(0, 10px);
    }
}

@keyframes waveAction {
    0% {
        -webkit-transform: translate(-150px, 0);
    }

    100% {
        -webkit-transform: translate(0, 0);
    }
}

.bowl {
    position: relative;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    box-shadow: inset 0 -5px 0 0 rgba(0, 0, 0, 0.5), inset 0 -20px 5px 0 rgba(0, 0, 0, 0.2), inset -15px 0 5px 0 rgba(0, 0, 0, 0.1), inset 15px 0 5px 0 rgba(0, 0, 0, 0.1);
    background: -moz-radial-gradient(center, ellipse cover, transparent 0%, transparent 76%, rgba(0, 0, 0, 0.65) 100%);
    background: -webkit-radial-gradient(center, ellipse cover, transparent 0%, transparent 76%, rgba(0, 0, 0, 0.65) 100%);
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 76%, rgba(0, 0, 0, 0.65) 100%);
    margin: 20px;
}

.bowl:before {
    overflow: hidden;
    border-radius: 50%;
    content: "";
    box-shadow: inset 0 -5px 0 0 rgba(0, 0, 0, 0.5), inset 0 -20px 5px 0 rgba(0, 0, 0, 0.2), inset -15px 0 5px 0 rgba(0, 0, 0, 0.1), inset 15px 0 5px 0 rgba(0, 0, 0, 0.1);
    background: -moz-radial-gradient(center, ellipse cover, transparent 0%, transparent 60%, rgba(0, 0, 0, 0.65) 81%, black 100%);
    background: -webkit-radial-gradient(center, ellipse cover, transparent 0%, transparent 60%, rgba(0, 0, 0, 0.65) 81%, black 100%);
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.65) 81%, #000000 100%);
    position: absolute;
    width: 150px;
    height: 150px;
    z-index: 2;
}

.bowl:after {
    content: "";
    width: 60px;
    border-radius: 50%;
    height: 5px;
    background: #039be4;
    box-shadow: inset 0 0 10px 0 #000;
    position: absolute;
    left: 50%;
    margin-left: -30px;
    bottom: 0;
    z-index: 2;
}

.inner-text {
    position: absolute;
    top: 20px;
    left: 38px;
    font-size: 41px;
}

.bowl .inner {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    background: -moz-radial-gradient(center, ellipse cover, transparent 0%, transparent 76%, rgba(0, 0, 0, 0.65) 100%);
    background: -webkit-radial-gradient(center, ellipse cover, transparent 0%, transparent 76%, rgba(0, 0, 0, 0.65) 100%);
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 76%, rgba(0, 0, 0, 0.65) 100%);
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
}

.bowl .inner:before {
    content: "";
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    position: absolute;
    right: 40%;
    top: 60%;
    z-index: 2;
}

.bowl .inner:after {
    content: "";
    width: 20px;
    height: 40px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    position: absolute;
    right: 30%;
    top: 15%;
    transform: rotate(-20deg);
    z-index: 2;
}

.bowl .fill {
    -webkit-animation-name: fillAction;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: cubic-bezier(0.2, 0.6, 0.8, 0.4);
    -webkit-animation-duration: 4s;
    -webkit-animation-fill-mode: forwards;
}

.waveShape {
    animation-name: waveAction;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -webkit-animation-duration: 0.5s;
    width: 300px;
    height: 150px;
    fill: #039be4;
}

.heat-connect {
    position: relative;
    width: 100%;

    margin-right: -20px;
}

@keyframes heatAnim {
    0% {
        left: 0;
        opacity: 1;
    }

    100% {
        left: 100%;
        opacity: 1;
    }
}

.heat-cirl {
    position: absolute;
    opacity: 0;
    top: 0px;
    width: 15px;
    height: 15px;
    background-color: rgb(7, 245, 35);
    border: 1px solid rgb(7, 245, 35);
    border-radius: 50%;
    animation: heatAnim 6s infinite linear;
}


.solor-connect {
    position: relative;
    width: 100%;
    margin-left: -20px;
}

@keyframes solorAnim {
    0% {
        left: 98%;
        opacity: 1;
    }

    100% {
        left: 0%;
        opacity: 1;
    }
}

.solor-cirl {
    position: absolute;
    opacity: 0;
    top: 0px;
    left: 100%;
    width: 15px;
    height: 15px;
    background-color: rgb(7, 245, 35);
    border: 1px solid rgb(7, 245, 35);
    border-radius: 50%;
    animation: solorAnim 6s infinite linear;
}

.cirl1 {
    animation-delay: 0.5s;
}

.cirl2 {
    animation-delay: 1s;
}

.cirl3 {
    animation-delay: 1.5s;
}

.cirl4 {
    animation-delay: 2s;
}

.cirl5 {
    animation-delay: 2.5s;
}

.cirl6 {
    animation-delay: 3s;
}

.cirl7 {
    animation-delay: 3.5s;
}

.cirl8 {
    animation-delay: 4s;
}

.cirl9 {
    animation-delay: 4.5s;
}

.cirl10 {
    animation-delay: 5s;
}

.cirl11 {
    animation-delay: 5.5s;
}

.cirl12 {
    animation-delay: 6s;
}